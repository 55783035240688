<template>
  <div id="app">
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
import { mixin as VueTimers } from "vue-timers"
import * as log from "loglevel"
import { mapGetters } from "vuex"
import { detect } from "detect-browser"
import AppVersionRepository from "@/repositories/app_version_repository.js"
import utils from "@/utils"

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Willkommen",
    // all titles will be injected into this template
    titleTemplate: "%s | Familien.app"
  },
  name: "App",
  components: {},
  mixins: [VueTimers],
  data() {
    return {
      showingVersionWarning: false,
      versionNotification: null
    }
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout"
    },
    ...mapGetters("auth", ["authenticated", "userData"])
  },
  mounted() {
    const browser = detect()
    // handle the case where we don't detect the browser
    switch (browser && browser.name) {
      case "ie":
        this.$alert(
          `
          Die Familien.app ist mit veralteten Browsern wie dem Internet Explorer 11 nicht kompatibel.
          Bitte nutzen Sie einen modernen Browser wie Firefox, Google Chrome,
          oder Microsoft Edge.
        `,
          "Ihre Browserversion ist veraltet",
          {
            dangerouslyUseHTMLString: false,
            showClose: false,
            confirmButtonText: "Mehr erfahren",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            callback: action => {
              if (action === "confirm") {
                window.open(
                  "https://familienapp.helpscoutdocs.com/article/70-browserunterstutzung-der-familien-app-verwaltungsoberflache"
                )
                window.location.reload()
              }
            }
          }
        )
        break
    }

    this.checkForUpdate()
  },
  methods: {
    async checkForUpdate() {
      let remoteVersion = await AppVersionRepository.get()
      let localVersion = process.env.VUE_APP_VERSION

      if (!this.showingVersionWarning) {
        log.info(
          `FRONTEND UPDATER: Warning is not shown at the moment local: ${localVersion} | remote: ${remoteVersion}`
        )
        if (remoteVersion != localVersion) {
          log.info("FRONTEND UPDATER: New Version is available")
          this.showVersionWarning()
          utils.addErrorBreadcrumb({
            category: "Frontend Updater", // e.g. "UI", "Network", "Navigation", "Console"
            action: "New version is available", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
            metadata: {} // key/value metadata in <string, string> format
          })
        } else {
          log.info("FRONTEND UPDATER: no new version available")
        }
      } else {
        log.info("FRONTEND UPDATER: closing old notification")
        this.versionNotification.close()
        this.showingVersionWarning = false
        this.showVersionWarning()
      }
    },
    showVersionWarning() {
      this.showingVersionWarning = true
      this.versionNotification = this.$notify({
        position: "top-left",
        title: "Neue Familien.app Version verfügbar",
        message:
          "Bitte klicken Sie hier, um die Seite neu zu laden und die App auf die neueste Version zu aktualisieren.",
        duration: 0,
        type: "warning",
        showClose: false,
        onClick: function() {
          //window.location.reload()
          window.location.href =
            window.location.href + "?t=" + Math.floor(Date.now() / 1000)
        }
      })
    }
  },
  timers: {
    checkForUpdate: {
      time: 1000 * 60,
      autostart: true,
      repeat: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
}
</style>
